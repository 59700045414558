import { Component, h } from 'preact';
import { Text } from 'preact-i18n';

import Header from '../../components/header/index';
import {
  ChallengeService,
  ChallengeTypes,
} from '../../domains/challenges/api/services/challenge';
import Challenge from '../../domains/challenges/index';
import {
  routeToChallenge,
  triggerAuthorizationRedirect,
} from '../../domains/challenges/utils/redirects';
import { isMobile } from '../../helpers/environment';
import rollbar from '../../utils/rollbar';
import { WeTrackEvents, WeTrackGroup } from '../../utils/wetrack';
import { LoginConstants } from './constants';

import './index.css';

interface LoginProps {
  challengeType?: string;
}

export default class Login extends Component<LoginProps> {
  constructor(props: LoginProps, context?: any) {
    super(props, context);
    this.continueFlow = this.continueFlow.bind(this);
  }

  /**
   * @method componentDidMount
   * @description Start the flow if the current scene
   * matches with the first challenge type
   */
  public componentDidMount() {
    if (this.props.challengeType === 'email') {
      this.startFlow();
    }
  }

  /**
   * @method componentWillUpdate
   * @description Assume if the user lands on the email scene,
   * it should start/restart challenge flow
   */
  public componentWillUpdate(nextProps) {
    const shouldRestartFlow =
      nextProps.challengeType === 'email' &&
      nextProps.challengeType !== this.props.challengeType;

    if (shouldRestartFlow) {
      this.startFlow();
    }
  }

  public render() {
    const termsLink = LoginConstants.legalFooterURI.termsOfService;
    const privacyLink = LoginConstants.legalFooterURI.privacyPolicy;

    return (
      <div className="login-container">
        <Header />
        <section className="login-component">
          <Challenge
            challengeType={this.props.challengeType}
            continueFlow={this.continueFlow}
          />
          <footer>
            <p class="ray-text--body-x-small">
              <Text id="scenes.login.legalDescriptionFragment">
                {LoginConstants.legalFooter.legalDescriptionFragment}
              </Text>
              <br />
              <a
                href={termsLink}
                target={isMobile() ? '_self' : '_blank'}
                onClick={this.trackLinkClick(WeTrackEvents.TERMS)}
              >
                <Text id="scenes.login.termsOfService">
                  {LoginConstants.legalFooter.termsOfService}
                </Text>
              </a>
              {' & '}
              <a
                href={privacyLink}
                target={isMobile() ? '_self' : '_blank'}
                onClick={this.trackLinkClick(WeTrackEvents.PRIVACY_POLICY)}
              >
                <Text id="scenes.login.privacyPolicy">
                  {LoginConstants.legalFooter.privacyPolicy}
                </Text>
              </a>
            </p>
          </footer>
        </section>
      </div>
    );
  }

  /**
   * @method trackLinkClick
   * @param name Target event name
   */
  private trackLinkClick = (name: string) => {
    return () =>
      this.context.WeTrack.track({
        name,
        group: WeTrackGroup,
        level: 'info',
      });
  };

  /**
   * @method startFlow
   * @description Starts the challenge flow steps
   */
  private async startFlow() {
    const challengeService = this.context.challengeService as ChallengeService;

    try {
      const result = await challengeService.startChallenge();
      routeToChallenge(result.nextChallenge, false);
    } catch (e) {
      rollbar.error('Error occurred while starting the challenge flow', e);
      this.handleError('errors.generalError');
    }
  }

  /**
   * @method continueFlow
   * @param challengeType One of email & password
   * @param challengeValue Response values to challenge types above
   */
  private async continueFlow(
    challengeType: ChallengeTypes,
    challengeValue: string,
  ) {
    const challengeService = this.context.challengeService as ChallengeService;

    try {
      const response = await challengeService.continueChallenge(
        challengeType,
        challengeValue,
      );

      switch (response.type) {
        case 'continue':
          routeToChallenge(response.nextChallenge, true);
          return true;

        case 'completed':
          triggerAuthorizationRedirect();
          return true;

        case 'flow-timeout':
          this.handleError('errors.timeoutError');
          rollbar.log('error', 'Challenge flow timeout error occurred');
          this.startFlow();
          return false;

        case 'failed':
          rollbar.log('error', 'Challenge failed error occurred');
          return false;

        case 'server-failed':
          rollbar.log('error', 'Server error occurred during challenge flow');
          return false;
      }
    } catch (e) {
      rollbar.error('Error occurred while continuing the challenge flow', e);
      this.handleError('errors.generalError');
      return true;
    }
  }

  private handleError(errorCode: string) {
    this.context.displayError(errorCode);
  }
}
