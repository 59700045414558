const errors = {
  invalidEmail: 'Invalid Email, please try again',
  invalidPassword: 'Invalid Password, please try again',
  serverError: 'Error contacting server',
  generalError: 'Something went wrong, please try again',
  check400Error: 'Invalid Client ID or Redirect URI',
};

const forms = {
  next: 'Next',
  email: 'Email',
  password: 'Password',
  forgotPasswordLink: 'Forgot Password?',
};

const apiErrors = {
  unknown: 'UnknownError',
  network: 'NetworkError',
};

export const ChallengeConstants = {
  errors,
  forms,
  apiErrors,
};
