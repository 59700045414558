import Rollbar from 'rollbar';

const rollbar = Rollbar.init({
  accessToken: process.env.ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled:
    process.env.APP_ENV === 'production' ||
    process.env.APP_ENV === 'staging' ||
    process.env.APP_ENV === 'development',
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        code_version: process.env.VERSION,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
});

export default rollbar;
