import { Component, h, render } from 'preact';
import Animate from 'preact-animate';
import { IntlProvider, Text } from 'preact-i18n';
import Router, { route } from 'preact-router';
import qs from 'qs';
import AnimationContainer from './components/animation-container';
import AnimationWrapper from './components/animation-wrapper';
import Toast from './components/toast';
import {
  ChallengeService,
  ChallengeServiceImpl,
} from './domains/challenges/api/services/challenge';
import { ChallengeServiceFake } from './domains/challenges/api/services/challenge-fake';
import { isMobile } from './helpers/environment';
import { getStyleState } from './helpers/styles';
import './index.css';
import './mobile-style.css';
import routes from './routes.json';
import BadRequest from './scenes/bad-request';
import ForgotPassword from './scenes/forgot';
import InstructionsSent from './scenes/forgot/instructions-sent';
import Login from './scenes/login';
import translations from './translations';
import LocaleManager from './utils/locale-manager';
import { WeTrack } from './utils/wetrack';

if (process.env.NODE_ENV === 'development' && module.hot) {
  import('preact/debug');
}

interface PreactRouterEvent {
  active: any;
  current: any;
  previous: string;
  router: any;
  url: string;
}

class App extends Component {
  public state = {
    visible: false,
    errorID: '',
    errorCode: '',
  };

  private definition: any;
  private rtl: boolean;
  private challengeService: ChallengeService;
  private style: string;

  constructor(props: {}) {
    super(props);

    this.style = getStyleState();

    const localeManager = new LocaleManager(translations, 'en-US');
    const locale = localeManager.selectLocale(
      window.location.search,
      navigator.languages,
    );

    this.definition = localeManager.getLocaleTranslation(locale);
    this.rtl = localeManager.isRTL(locale);

    this.challengeService =
      process.env.FAKE_CHALLENGE_SERVICE === '1'
        ? new ChallengeServiceFake()
        : new ChallengeServiceImpl();
  }

  /**
   * @method componentDidMount
   * @description On component load, ensures that
   * fade-in animation is visible on page load
   */
  public componentDidMount() {
    this.setState({ visible: true });
  }

  /**
   * @method getChildContext
   * @description Shared context with child components
   */
  public getChildContext() {
    return {
      displayError: (errorCode: string) => {
        this.setState({
          errorID: Math.random(),
          errorCode,
        });
      },
      challengeService: this.challengeService,
      WeTrack,
    };
  }

  public render() {
    return (
      <IntlProvider definition={this.definition}>
        <section
          className={`container app ${this.style} ${
            isMobile() ? 'mobile-view' : 'desktop-view'
          }`}
          dir={this.rtl ? 'rtl' : 'ltr'}
        >
          <Animate
            showProp="visible"
            transitionAppear={true}
            component={AnimationContainer}
            transitionName="fadeTransition"
          >
            <AnimationWrapper visible={this.state.visible}>
              <Toast
                id={this.state.errorID}
                message={<Text id={this.state.errorCode} />}
              />
              <Router onChange={this.handleRoute}>
                <Login path={routes.email} challengeType="email" />
                <Login path={routes.password} challengeType="password" />
                <ForgotPassword path={routes.forgot} />
                <InstructionsSent path={routes.forgotInstructions} />
                <BadRequest path={routes.badRequest} />
              </Router>
            </AnimationWrapper>
          </Animate>
        </section>
      </IntlProvider>
    );
  }

  /**
   * @method handleRoute
   * @description When route lands on root or `/login`
   * make sure that it re-routes to the first challenge
   */
  private handleRoute(event) {
    if (event.url === '/' || event.url.match(/^\S+login$|login\/$|login\?/i)) {
      route(`${routes.email}${window.location.search}`, true);
    }
  }
}

render(<App />, document.body, document.body.lastChild as Element);
