import { h } from 'preact';

import './index.css';

interface TextInputProps {
  type: string;
  value: string;
  label: string | JSX.Element;
  example?: string;
  fieldActionIcon?: JSX.Element;
  onChange?: (event: Event) => void;
  errorMessage?: string | JSX.Element;
}

const TextInput = (props: TextInputProps) => {
  let fieldClassName = 'ray-text-field';
  if (!!props.errorMessage) {
    fieldClassName += ' ray-text-field--error';
  }
  if (props.fieldActionIcon) {
    fieldClassName += ' ray-text-field--with-icon-end';
  }

  return (
    <div>
      <div class={fieldClassName}>
        {props.fieldActionIcon && (
          <div class="ray-text-field__icon--end">{props.fieldActionIcon}</div>
        )}
        <input
          id="challenge-input"
          className="ray-text-field__input"
          type={props.type}
          value={props.value}
          onInput={props.onChange}
          placeholder={props.example}
        />
        <label class="ray-text-field__label" for="challenge-input">
          {props.label}
        </label>
      </div>
      {props.errorMessage && (
        <div class="ray-form-item__hint ray-form-item__hint--error">
          {props.errorMessage}
        </div>
      )}
    </div>
  );
};

export default TextInput;
