import { Component } from 'preact';

export default abstract class PureComponent<P> extends Component<P> {
  constructor(props?: P) {
    super(props);
  }

  public shouldComponentUpdate(props, state) {
    return !(
      shallowEqual(props, this.props) && shallowEqual(state, this.state)
    );
  }
}

function shallowEqual(a, b) {
  for (const key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  for (const key in b) {
    if (!(key in a)) {
      return false;
    }
  }
  return true;
}
