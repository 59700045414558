import { WeTrackFactory } from '@wework/core-web-wetrack';
import { parse } from 'query-string';
import packageJson from '../../../package.json';
import { getEnvironment } from '../../helpers/environment';

const { client_id } = parse(window.location.search);

export const WeTrackGroup = 'auth_login_portal';

export const WeTrackEvents = {
  CLICK_NEXT: 'click_next',
  EMAIL_CHALLENGE: 'email_challenge',
  PASSWORD_CHALLENGE: 'password_challenge',
  FORGOT_PASSWORD: 'forgot_password',
  PRIVACY_POLICY: 'privacy_policy',
  TERMS: 'terms',
};

export const WeTrack = WeTrackFactory.init({
  env: getEnvironment(),
  apiKey: process.env.WETRACK_API_KEY,
  common: {
    app_package_id: packageJson.name,
    app_version_name: packageJson.version,
    component_name: 'Login Portal',
    platform: 'web',
    client_id: String(client_id),
  },
});
