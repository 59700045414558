import qs from 'qs';

export enum StyleStates {
  RAY = 240,
  DIETER = 205,
  TANOOKI = 338,
}

/**
 * getStyleState
 * @description Returns specified style given on instantiation,
 * otherwise fall back on Tanooki styles
 *
 * TODO: TESTS and should be changed to Ray as the default style,
 * this current default is to prevent breaking HPRoam team theming
 */
export const getStyleState = () => {
  const parsedQuery = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const hueValue = parsedQuery && parsedQuery.st;

  return hueValue ? StyleStates[`${hueValue}`].toLowerCase() : 'tanooki';
};
