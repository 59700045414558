import { Component, h } from 'preact';
import { Text, withText } from 'preact-i18n';

import SubmitButton from '../../../components/submit-button';
import { routeWithQueryParams } from '../../../domains/challenges/utils/redirects';

import '../index.css';

const button = <Text id="forms.login" />;

const TranslatedButton = withText({
  value: button,
})(SubmitButton);

export default class InstructionsSent extends Component {
  public render() {
    return (
      <div class="forgot-container">
        <header>
          <h1 class="ray-text--h2">
            <Text id="scenes.forgotPassword.instructionsSent" />
          </h1>
          <p class="ray-text--body">
            <Text id="scenes.forgotPassword.instructionsSentDetails" />
          </p>
        </header>
        <form>
          <div class="forgot-form-spacer" />
          <TranslatedButton onClick={this.handleSubmit} />
        </form>
      </div>
    );
  }

  private async handleSubmit(event: Event) {
    event.preventDefault();
    routeWithQueryParams('/login/email', true);
  }
}
