import { Component, h } from 'preact';
import { Text, withText } from 'preact-i18n';

import { WeTrackEvents, WeTrackGroup } from '../../../utils/wetrack';
import { ChallengeTypes } from '../api/services/challenge';
import TextBasedChallengeForm from '../components/text-based-challenge-form';
import { ChallengeConstants } from '../constants';
import { isEmailValid } from '../utils/regex';

import './index.css';

// props passed by challenge root
interface EmailProps {
  continueFlow: (
    challengeType: ChallengeTypes,
    challengeValue: string,
  ) => Promise<boolean>;
  fieldLabel: string;
  invalidFieldMessage: string;
}

class Email extends Component<EmailProps> {
  private static validate(input: string) {
    return isEmailValid(input);
  }

  constructor(props, context) {
    super(props, context);
    this.continueFlow = this.continueFlow.bind(this);
  }

  public componentDidMount() {
    this.context.WeTrack.track({
      name: WeTrackEvents.EMAIL_CHALLENGE,
      group: WeTrackGroup,
      level: 'info',
    });
  }

  public render() {
    return (
      <TextBasedChallengeForm
        validate={Email.validate}
        fieldType="email"
        fieldLabel={this.props.fieldLabel}
        fieldExample=" "
        invalidFieldMessage={this.props.invalidFieldMessage}
        continueFlow={this.continueFlow}
      />
    );
  }

  private continueFlow(challengeValue: string) {
    return this.props.continueFlow(ChallengeTypes.email, challengeValue);
  }
}

const fieldLabel = (
  <Text id="challenges.email">{ChallengeConstants.forms.email}</Text>
);

const invalidFieldMessage = (
  <Text
    id="errors.invalidChallenge"
    fields={{
      challenge: ChallengeConstants.forms.email,
    }}
  >
    {`Invalid ${ChallengeConstants.forms.email}, please try again.`}
  </Text>
);

export default withText({
  fieldLabel,
  invalidFieldMessage,
})(Email);
