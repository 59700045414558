/**
 * Determine whether user is on mobile or desktop
 */
export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

/**
 * Get current server environment
 */
export const getEnvironment = () => {
  const env = process.env.APP_ENV;
  const environments = ['development', 'staging', 'production'];
  // TODO: Remove when we've sorted out creating a fresh docker build for
  // build for production. At the moment, it's re-using the container that
  // was built for staging environment. (default back to `development` not `staging`)
  const isProd = window.location.hostname.includes('we.id');

  // default to production
  return (
    (isProd && 'production') ||
    (env && environments.includes(env) ? env : 'production')
  );
};
