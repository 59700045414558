import translations_ar from './ar.json';
import translations_ca from './ca.json';
import translations_cs from './cs.json';
import translations_da_DK from './da-DK.json';
import translations_de from './de.json';
import translations_en from './en.json';
import translations_es_419 from './es-419.json';
import translations_es from './es.json';
import translations_fi from './fi.json';
import translations_fr_CA from './fr-CA.json';
import translations_fr from './fr.json';
import translations_he_IL from './he-IL.json';
import translations_he from './he.json';
import translations_hu from './hu.json';
import translations_id from './id.json';
import translations_it from './it.json';
import translations_ja from './ja.json';
import translations_ko from './ko.json';
import translations_ms from './ms.json';
import translations_nb from './nb.json';
import translations_nl from './nl.json';
import translations_pl from './pl.json';
import translations_pt_BR from './pt-BR.json';
import translations_pt from './pt.json';
import translations_ru from './ru.json';
import translations_sv from './sv.json';
import translations_th from './th.json';
import translations_vi from './vi.json';
import translations_zh_TW from './zh-TW.json';
import translations_zh from './zh.json';

export default {
  ar: translations_ar,
  'da-DK': translations_da_DK,
  'he-IL': translations_he_IL,
  ca: translations_ca,
  cs: translations_cs,
  de: translations_de,
  en: translations_en,
  es: translations_es,
  'es-419': translations_es_419,
  fi: translations_fi,
  fr: translations_fr,
  'fr-CA': translations_fr_CA,
  he: translations_he,
  hu: translations_hu,
  id: translations_id,
  it: translations_it,
  ja: translations_ja,
  ko: translations_ko,
  ms: translations_ms,
  nb: translations_nb,
  nl: translations_nl,
  pl: translations_pl,
  pt: translations_pt,
  'pt-BR': translations_pt_BR,
  ru: translations_ru,
  sv: translations_sv,
  th: translations_th,
  vi: translations_vi,
  zh: translations_zh,
  'zh-TW': translations_zh_TW,
};
