import { h } from 'preact';
import PureComponent from '../../utils/preact-purecomponent';

import './index.css';

interface ToastProps {
  id: string;
  message: string | JSX.Element;
}

export default class Toast extends PureComponent<ToastProps> {
  public state = {
    visible: false,
  };

  private toastTimeout;

  constructor(props: ToastProps) {
    super(props);
  }

  public componentDidUpdate(props, state) {
    if (props.id !== this.props.id) {
      this.showMessage();
    }
  }

  public render() {
    if (!this.state.visible) {
      return;
    }

    return (
      <section
        className={`toast-container ${
          this.state.visible ? 'toast-container--show' : 'toast-container--hide'
        }`}
      >
        <p className="toast-message">{this.props.message}</p>
      </section>
    );
  }

  private showMessage() {
    this.setState({ visible: true });
    clearTimeout(this.toastTimeout);
    this.toastTimeout = setTimeout(() => {
      this.setState({ visible: false });
    }, 3500);
  }
}
