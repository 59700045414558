import { ChallengeConstants } from '../../constants';

/**
 * The challenge types we expect to be returned by the challenge flow backend.
 * This corresponds to: https://github.com/WeConnect/identity-authentication/blob/master/internal/app/challenge_values.go
 */
export enum ChallengeTypes {
  email = 'email',
  password = 'password',
}

/**
 * Indicates that the previous challenge response was accepted and a new
 * challenge type is now being presented.
 */
export interface ChallengeContinueResponse {
  type: 'continue';
  nextChallenge: ChallengeTypes;
}

/**
 * Readability enhancing function for producing `ChallengeContinueResponse`
 * instances.
 */
export function continueResponse(
  nextChallenge: ChallengeTypes,
): ChallengeContinueResponse {
  return { type: 'continue' as 'continue', nextChallenge };
}

/**
 * Indicates that the challenge flow is complete and the subject is now
 * authenticated.
 */
export const challengeCompleted = { type: 'completed' as 'completed' };

/**
 * Indicates that the last challenge flow response was incorrect.
 */
export const challengeFailed = { type: 'failed' as 'failed' };

/**
 * Indicates that a server error has taken place during challenge flow
 */
export const serverFailed = { type: 'server-failed' as 'server-failed' };

/**
 * Indicates that the user has taken too long to complete the challenge
 * flow, and that the flow should be restarted.
 */
export const challengeFlowTimeout = { type: 'flow-timeout' as 'flow-timeout' };

/**
 * The possible types of response to a challenge request.
 */
export type ChallengeResponse =
  | ChallengeContinueResponse
  | typeof challengeCompleted
  | typeof challengeFailed
  | typeof serverFailed
  | typeof challengeFlowTimeout;

/**
 * Indicates that an error occurred. The code uniquely identifies the location
 * in the code where the error was identified, while the error value optionally
 * provides additional detail on the actual error object observed.
 */
export class UnknownError extends Error {
  public errorCode: string;
  public error?: Error;

  constructor(errorCode: string, error?: Error) {
    const message = (error && error.message) || '';

    super(message);

    this.name = ChallengeConstants.apiErrors.unknown;
    this.errorCode = errorCode;
    this.error = error;
  }
}

/**
 * Indicates that a network error occurred.
 */
export class NetworkError extends UnknownError {
  constructor(errorCode: string, error?: any) {
    super(errorCode, error);
    this.name = ChallengeConstants.apiErrors.network;
  }
}

/**
 * The possible types of API errors.
 */
export type ApiError = UnknownError | NetworkError;

/**
 * The operations that the identity-authentication backend service provides
 * to cooperate with the login portal frontend to authenticate a user. The
 * most important of these is the "challenge flow" endpoints, which provide
 * a means to drive a shared state machine to authenticate a subject.
 */
export interface ChallengeService {
  /**
   * Starts, or restarts, an authentication challenge flow.
   */
  startChallenge(): Promise<ChallengeContinueResponse>;

  /**
   * Presents a response to a previous challenge, to advance through the
   * challenge flow.
   */
  continueChallenge(
    challengeType: ChallengeTypes,
    challengeValue: string,
  ): Promise<ChallengeResponse>;

  /**
   * Requests that the password associated with the specified identifier
   * be reset. Returns false if the email doesn't match any known account.
   */
  resetPassword(email: string): Promise<boolean>;
}

/**
 * The default implementation of `ChallengeService`. Sends requests over
 * the network to identity-authentication; in production, this will share
 * the same domain as the login portal frontend; for local testing, it
 * may be to a different domain.
 */
export class ChallengeServiceImpl implements ChallengeService {
  public async startChallenge(): Promise<ChallengeContinueResponse> {
    let response: Response;
    try {
      response = await this.jsonPost('/api/challenge/start');
    } catch (error) {
      // fetch promise was rejected - typically a routing issue
      throw new NetworkError('ERR-CHST-1', error);
    }

    if (response.status !== 200) {
      // server responded with an unexpected HTTP status code
      throw new UnknownError('ERR-CHST-4');
    }

    let body: any;
    try {
      body = await response.json();
    } catch (error) {
      // decode promise failed - missing body or malformed JSON content
      throw new UnknownError('ERR-CHST-2', error);
    }

    if (!ChallengeTypes[body.next_challenge]) {
      // next challenge type is unrecognized
      throw new UnknownError('ERR-CHST-3');
    }

    return continueResponse(body.next_challenge);
  }

  public async continueChallenge(
    challengeType: ChallengeTypes,
    challengeValue: string,
  ): Promise<ChallengeResponse> {
    let response: Response;
    try {
      response = await this.jsonPost('/api/challenge', {
        challenge_type: challengeType,
        challenge_value: challengeValue,
      });
    } catch (error) {
      // fetch promise was rejected - typically a routing issue
      throw new NetworkError('ERR-CH-1', error);
    }

    if (response.status === 200) {
      let body: any;
      try {
        body = await response.json();
      } catch (error) {
        // decode promise failed - missing body or malformed JSON content
        throw new UnknownError('ERR-CH-2', error);
      }

      if (!ChallengeTypes[body.next_challenge]) {
        // next challenge type is unrecognized
        throw new UnknownError('ERR-CH-3');
      }

      return continueResponse(body.next_challenge);
    }

    if (response.status === 201) {
      return challengeCompleted;
    }

    if (response.status === 400 || response.status === 404) {
      return challengeFailed;
    }

    if (response.status === 401) {
      return challengeFlowTimeout;
    }

    if (response.status === 503) {
      return serverFailed;
    }

    // code did not match any expected codes
    throw new UnknownError('ERR-CH-4');
  }

  public async resetPassword(email: string): Promise<boolean> {
    const response = await this.jsonPost('/api/reset-password', { email });
    if (response.status === 200) {
      return true;
    }

    if (response.status === 404) {
      return false;
    }

    // code did not match any expected codes
    throw new UnknownError('ERR-RP-1');
  }

  private jsonPost(
    path: string,
    data: object | null = null,
  ): Promise<Response> {
    const mode = !!process.env.AUTH_BASE_URI ? 'cors' : 'same-origin';
    const baseUri = process.env.AUTH_BASE_URI || '';
    return fetch(`${baseUri}${path}`, {
      method: 'POST',
      mode,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: !!data ? JSON.stringify(data) : undefined,
    });
  }
}
