import qs from 'qs';

export interface LocaleTranslations {
  [key: string]: any;
}

const rtlLanguages = new Set(['he']);

export default class LocaleManager {
  private localeTranslations: LocaleTranslations;
  private defaultLocale: string;
  private localeCodes: Set<string>;

  constructor(localeTranslations: object, defaultLocale: string) {
    this.localeTranslations = localeTranslations;
    this.defaultLocale = defaultLocale;
    this.localeCodes = new Set(Object.keys(localeTranslations));
  }

  public isRTL(locale: string): boolean {
    return rtlLanguages.has(locale);
  }

  public getLocaleTranslation(locale: string) {
    return this.localeTranslations[locale];
  }

  public selectLocale(
    queryString: string,
    browserLocales: ReadonlyArray<string>,
  ) {
    const queryLocales = this.getLocalesFromQuery(queryString);
    const locales = this.produceLocaleList(...queryLocales, ...browserLocales);

    for (let i = 0; i < locales.length; i++) {
      const locale = locales[i];
      if (this.localeCodes.has(locale)) {
        return locale;
      }
    }

    return this.defaultLocale;
  }

  private getLocalesFromQuery(queryString: string): string[] {
    const parsedQuery = qs.parse(queryString, { ignoreQueryPrefix: true });
    if (parsedQuery.ui_locales) {
      return parsedQuery.ui_locales.split(' ');
    }

    return [];
  }

  private produceLocaleList(...locales: string[]): string[] {
    const seenLocale = new Set();
    const langOrder = [];
    const langCountryOrder = {};

    locales.forEach((locale) => {
      if (seenLocale.has(locale)) {
        return;
      }
      seenLocale.add(locale);

      const lang = this.stripCountry(locale);
      if (!(lang in langCountryOrder)) {
        langOrder.push(lang);
        langCountryOrder[lang] = [];
      }

      if (locale !== lang) {
        langCountryOrder[lang].push(locale);
      }
    });

    const orderedLocales = [];
    langOrder.forEach((lang) => {
      orderedLocales.push(...langCountryOrder[lang]);
      orderedLocales.push(lang);
    });

    return orderedLocales;
  }

  private stripCountry(locale: string): string {
    const countryIndex = locale ? locale.indexOf('-') : 0;
    if (countryIndex > 0) {
      return locale.substring(0, countryIndex);
    }
    return locale;
  }
}
