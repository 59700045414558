import { Component, h } from 'preact';

import { ChallengeTypes } from './api/services/challenge';
import Email from './email';
import Password from './password';

import './index.css';

export interface ChallengeProps {
  challengeType?: string;
  continueFlow: (
    challengeType: ChallengeTypes,
    challengeValue: string,
  ) => Promise<boolean>;
}

export default class Challenge extends Component<ChallengeProps> {
  constructor(props: ChallengeProps, context?: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  public render() {
    return this.selectChallenge();
  }

  public handleChange(event: Event) {
    const HTMLInputEventTarget = event.target! as HTMLInputElement;
    this.setState({ email: HTMLInputEventTarget!.value });
  }

  private selectChallenge() {
    switch (this.props.challengeType) {
      case 'email':
        return <Email continueFlow={this.props.continueFlow} />;
      case 'password':
        return <Password continueFlow={this.props.continueFlow} />;
      default:
        // just display an empty div; this prevents controls from moving
        // around during the transition
        return <div class="challenge-form" />;
    }
  }
}
