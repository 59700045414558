import * as PreactRouter from 'preact-router';

// TODO: Migrate into a util package when we move back to the monorepo
// this essentially wraps preact-router to preserve query params.
export const routeWithQueryParams = (
  url: string,
  shouldClearHistory?: boolean,
) => {
  PreactRouter.route(`${url}${window.location.search}`, shouldClearHistory);
};

export const routeToChallenge = (
  nextChallenge: string,
  shouldClearHistory: boolean,
) => {
  switch (nextChallenge) {
    case 'email':
      routeWithQueryParams('/login/email', shouldClearHistory);
      break;
    case 'password':
      routeWithQueryParams('/login/password', shouldClearHistory);
      break;
  }
};

export function triggerAuthorizationRedirect() {
  window.location.replace(`/oauth2/authorize${window.location.search}`);
}
