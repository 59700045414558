import { Component, h } from 'preact';
import { Text, withText } from 'preact-i18n';

import { WeTrackEvents, WeTrackGroup } from '../../../utils/wetrack';
import { ChallengeTypes } from '../api/services/challenge';
import TextBasedChallengeForm from '../components/text-based-challenge-form';
import { ChallengeConstants } from '../constants';
import { routeWithQueryParams } from '../utils/redirects';
import viewHideIcon from './view-hide.svg';
import viewShowIcon from './view-show.svg';

import './index.css';

interface PasswordProps {
  continueFlow: (
    challengeType: ChallengeTypes,
    challengeValue: string,
  ) => Promise<boolean>;
  fieldLabel: string;
  invalidFieldMessage: string;
}

interface PasswordState {
  showPassword: boolean;
}

class Password extends Component<PasswordProps, PasswordState> {
  private static validate(input) {
    return !!input;
  }

  constructor(props) {
    super(props);
    this.state = { showPassword: false };
    this.continueFlow = this.continueFlow.bind(this);
    this.togglePasswordView = this.togglePasswordView.bind(this);
  }

  public componentDidMount() {
    this.context.WeTrack.track({
      name: WeTrackEvents.PASSWORD_CHALLENGE,
      group: WeTrackGroup,
      level: 'info',
    });
  }

  public render({ fieldLabel, invalidFieldMessage }) {
    const routeToForgotPassword = () =>
      routeWithQueryParams('/login/password/forgot', true);
    const ForgotPasswordLink = (
      <a class="forgot-password" onClick={routeToForgotPassword}>
        <Text id="forms.forgotPassword">
          {ChallengeConstants.forms.forgotPasswordLink}
        </Text>
      </a>
    );

    return (
      <TextBasedChallengeForm
        validate={Password.validate}
        fieldType={this.state.showPassword ? 'text' : 'password'}
        fieldLabel={fieldLabel}
        fieldExample=" "
        continueFlow={this.continueFlow}
        fieldActionIcon={
          <div
            class="password-toggle-wrapper"
            onClick={this.togglePasswordView}
          >
            <img
              class="password-toggle"
              src={this.state.showPassword ? viewHideIcon : viewShowIcon}
            />
          </div>
        }
        children={ForgotPasswordLink}
        invalidFieldMessage={invalidFieldMessage}
      />
    );
  }

  private continueFlow(challengeValue: string) {
    return this.props.continueFlow(ChallengeTypes.password, challengeValue);
  }

  private togglePasswordView() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
}

const fieldLabel = (
  <Text id="challenges.password">{ChallengeConstants.forms.password}</Text>
);

const invalidFieldMessage = (
  <Text
    id="errors.invalidChallenge"
    fields={{
      challenge: ChallengeConstants.forms.password,
    }}
  >
    {`Invalid ${ChallengeConstants.forms.password}, please try again.`}
  </Text>
);

export default withText({
  fieldLabel,
  invalidFieldMessage,
})(Password);
