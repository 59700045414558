import { h } from 'preact';
import './index.css';

const AnimationWrapper = (props) => {
  const style = { display: props.visible ? 'block' : 'none' };
  return (
    <div className="animate-wrapper" style={style}>
      {props.children}
    </div>
  );
};

export default AnimationWrapper;
