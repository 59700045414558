import { Component, h } from 'preact';
import { Text, withText } from 'preact-i18n';

import { BadRequestConstants } from './constants';

import './index.css';

// query params come through preact-router as props
interface QueryParams {
  error_code?: string;
  redirect_to?: string;
}

export enum ErrorCodes {
  INVALID_CLIENTID = 'invalid_client',
  INVALID_REDIRECTURI = 'invalid_redirect_uri',
  INVALID_RESPONSETYPE = 'unsupported_response_type', // defined by https://tools.ietf.org/html/rfc6749#section-4.1.2.1
  INVALID_PKCE = 'invalid_code_verifier',
}

export default class BadRequest extends Component<QueryParams> {
  private static determineErrorCodeToDisplay(errorCode) {
    switch (errorCode) {
      case ErrorCodes.INVALID_CLIENTID:
        return BadRequestConstants.invalidClientID;
      case ErrorCodes.INVALID_REDIRECTURI:
        return BadRequestConstants.invalidRedirectURI;
      case ErrorCodes.INVALID_RESPONSETYPE:
        return BadRequestConstants.invalidResponseType;
      case ErrorCodes.INVALID_PKCE:
        return BadRequestConstants.invalidPKCE;
    }
  }

  constructor(props: QueryParams) {
    super(props);
    // TODO: Determine in v2 if we wish to redirect or not
    // if (props.redirect_to) {
    //   window.location.assign(props.redirect_to);
    // }
  }

  public render() {
    return (
      <div className="container">
        <section className="bad-request-component">
          <h1>
            <Text id="errors.somethingWentWrong">
              {BadRequestConstants.somethingWentWrong}
            </Text>
          </h1>
          <p>{BadRequest.determineErrorCodeToDisplay(this.props.error_code)}</p>
        </section>
      </div>
    );
  }
}
