import { Component, h } from 'preact';
import { Text, withText } from 'preact-i18n';

import SubmitButton from '../../../../components/submit-button';
import TextInput from '../../../../components/text-input';
import { WeTrackEvents, WeTrackGroup } from '../../../../utils/wetrack';

import './index.css';

interface InputProps {
  validate: (input: string) => boolean;
  children?: JSX.Element;
  fieldType: string;
  fieldLabel: string;
  fieldExample: string;
  fieldActionIcon?: JSX.Element;
  continueFlow: (challengeValue: string) => Promise<boolean>;
  invalidFieldMessage: string;
}

const nextButtonMessage = <Text id="forms.next">Next</Text>;

export const TranslatedNextButton = withText({
  value: nextButtonMessage,
})(SubmitButton);

export default class TextBasedChallengeForm extends Component<InputProps> {
  public state = {
    value: '',
    submitted: false,
    isButtonDisabled: true,
    showError: false,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public render() {
    return (
      <form class="challenge-form">
        <div className="form-wrapper">
          <TextInput
            type={this.props.fieldType}
            value={this.state.value}
            onChange={this.handleChange}
            label={this.props.fieldLabel}
            example={this.props.fieldExample}
            fieldActionIcon={this.props.fieldActionIcon}
            errorMessage={
              this.state.showError && this.props.invalidFieldMessage
            }
          />
          {this.props.children}
        </div>
        <TranslatedNextButton
          onClick={this.handleSubmit}
          submitted={this.state.submitted}
          disabled={this.state.isButtonDisabled}
        />
      </form>
    );
  }

  private handleChange(event: Event) {
    const HTMLInputEventTarget = event.target! as HTMLInputElement;
    const inputValue = HTMLInputEventTarget.value;

    this.setState({ value: inputValue });

    const validInput = this.props.validate(inputValue);
    const updatedState = validInput
      ? { isButtonDisabled: false, showError: false }
      : { isButtonDisabled: true, showError: false };

    this.setState(updatedState);
  }

  private async handleSubmit(event: Event) {
    event.preventDefault();

    this.context.WeTrack.track({
      name: WeTrackEvents.CLICK_NEXT,
      group: WeTrackGroup,
      level: 'info',
    });

    if (!this.state.isButtonDisabled) {
      const valid = await this.props.continueFlow(this.state.value);
      const updatedState = valid
        ? { submitted: true }
        : { isButtonDisabled: true, showError: true };

      this.setState(updatedState);
    }
  }
}
