import {
  challengeCompleted,
  ChallengeContinueResponse,
  challengeFailed,
  ChallengeResponse,
  ChallengeService,
  ChallengeTypes,
  continueResponse,
} from './challenge';

/**
 * A fake, local in-memory implementation of the `ChallengeService` interface
 * for isolated testing. Currently provides very basic "authentication" for any
 * wework.com email address with a fixed password, and enforces the expected
 * flow.
 */
export class ChallengeServiceFake implements ChallengeService {
  private currentChallenge: ChallengeTypes | null = null;

  public async startChallenge(): Promise<ChallengeContinueResponse> {
    this.currentChallenge = ChallengeTypes.email;
    return continueResponse(this.currentChallenge);
  }

  public async continueChallenge(
    challengeType: ChallengeTypes,
    challengeValue: string,
  ): Promise<ChallengeResponse> {
    if (challengeType !== this.currentChallenge) {
      return challengeFailed;
    }

    switch (challengeType) {
      case ChallengeTypes.email:
        if (this.allowEmail(challengeValue)) {
          this.currentChallenge = ChallengeTypes.password;
          return continueResponse(this.currentChallenge);
        } else {
          return challengeFailed;
        }
      case ChallengeTypes.password:
        if (this.allowPassword(challengeValue)) {
          this.currentChallenge = ChallengeTypes.email;
          return challengeCompleted;
        } else {
          return challengeFailed;
        }
      default:
        return challengeFailed;
    }
  }

  public async resetPassword(email: string): Promise<boolean> {
    // match any wework.com email address
    return !!email.match(/^\S+@wework\.com$/i);
  }

  private allowEmail(email: string) {
    // match any wework.com email address
    return !!email.match(/^\S+@wework\.com$/i);
  }

  private allowPassword(password: string) {
    // the best password, it's on my luggage
    // https://www.youtube.com/watch?v=a6iW-8xPw3k
    return password === '12345';
  }
}
