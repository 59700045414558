import { Component, h } from 'preact';
import { Text, withText } from 'preact-i18n';

import SubmitButton from '../../components/submit-button';
import TextInput from '../../components/text-input';
import { ChallengeService } from '../../domains/challenges/api/services/challenge';
import { ChallengeConstants } from '../../domains/challenges/constants';
import { routeWithQueryParams } from '../../domains/challenges/utils/redirects';
import { isEmailValid } from '../../domains/challenges/utils/regex';
import rollbar from '../../utils/rollbar';
import { WeTrackEvents, WeTrackGroup } from '../../utils/wetrack';

import './index.css';

const button = <Text id="forms.resetPassword" />;

const TranslatedButton = withText({
  value: button,
})(SubmitButton);

const fieldLabel = <Text id="challenges.email" />;

const fieldErrorMessage = (
  <Text
    id="errors.invalidChallenge"
    fields={{
      challenge: ChallengeConstants.forms.email,
    }}
  />
);

export default class ForgotPassword extends Component {
  public state = {
    email: '',
    submitted: false,
    isButtonDisabled: true,
    showError: false,
  };

  constructor(props?: object, context?: any) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    this.context.WeTrack.track({
      name: WeTrackEvents.FORGOT_PASSWORD,
      group: WeTrackGroup,
      level: 'info',
    });
  }

  public render() {
    return (
      <div class="forgot-container">
        <header>
          <h1 class="ray-text--h2">
            <Text id="forms.forgotPassword" />
          </h1>
          <p class="ray-text--body">
            <Text id="scenes.forgotPassword.resetInstructions" />
          </p>
        </header>
        <form>
          <TextInput
            type="email"
            value={this.state.email}
            label={fieldLabel}
            example=" "
            onChange={this.handleChange}
            errorMessage={this.state.showError && fieldErrorMessage}
          />
          <div class="forgot-form-spacer" />
          <TranslatedButton
            onClick={this.handleSubmit}
            submitted={this.state.submitted}
            disabled={this.state.isButtonDisabled}
          />
        </form>
      </div>
    );
  }

  private handleChange(event: Event) {
    const HTMLInputEventTarget = event.target! as HTMLInputElement;
    const email = HTMLInputEventTarget.value;

    this.setState({ email: HTMLInputEventTarget.value });
    this.setState({ email });

    const validInput = isEmailValid(`${email}`);
    const updatedState = validInput
      ? { isButtonDisabled: false, showError: false }
      : { isButtonDisabled: true, showError: false };

    this.setState(updatedState);
  }

  private async handleSubmit(event: Event) {
    const challengeService = this.context.challengeService as ChallengeService;

    this.setState({ submitted: true });

    try {
      const emailSent = await challengeService.resetPassword(this.state.email);

      if (emailSent) {
        routeWithQueryParams('/login/password/instructions', true);
      } else {
        this.setState({
          isButtonDisabled: true,
          showError: true,
          submitted: false,
        });
      }
    } catch (e) {
      rollbar.error('Error occurred while sending reset password email', e);
      this.context.displayError('errors.generalError');
    }
  }
}
