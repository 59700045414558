const legalFooter = {
  legalDescriptionFragment: 'By logging in you agree to our',
  termsOfService: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
};

const legalFooterURI = {
  termsOfService: 'https://www.wework.com/legal/member-network-terms',
  privacyPolicy: 'https://www.wework.com/legal/privacy',
};

export const LoginConstants = {
  legalFooter,
  legalFooterURI,
};
