import classnames from 'classnames';
import { h } from 'preact';
import './index.css';

const cx = classnames.bind('./index.css');

interface SubmitButtonProps {
  submitted: boolean;
  disabled: boolean;
  value: string;
  onClick: (Event) => void;
}

const SubmitButton = (props: SubmitButtonProps) => (
  <div
    className={cx('submit-button ray-button ray-button--primary', {
      disabled: props.disabled,
    })}
    onClick={props.onClick}
  >
    {!props.submitted ? (
      <input type="submit" disabled={props.disabled} value={props.value} />
    ) : (
      <div className="loader">
        <div className="dot-pulse" />
      </div>
    )}
  </div>
);

export default SubmitButton;
