import { Component, h } from 'preact';
import logo from '../../../img/wework-logo.svg';
import './index.css';

export default class Header extends Component {
  public render() {
    return (
      <header class="header-container">
        <img alt="logo" src={logo} width="188" height="40" />
      </header>
    );
  }
}
